<template>
  <Layout :loading="loading">
    <RoleModalForm
        :modalId="modalRoleId"
        :modalTitle="modalTitle"
        :btnText="btnText"
        :initialData="currentRoleRow"
        @onSubmit="handleRoleFormSubmit"
        @onClearForm="clearForm"
    />
    <UserServiceModalForm
      v-if="service"
      :mode="formMode"
      :modalId="modalUserId"
      :modalTitle="modalTitle"
      :btnText="btnText"
      :rolesOptions="rolesOptions"
      :employees="employees"
      :employeesOptions="employeesOptions"
      :initialData="currentEmployeeRow"
      @onSubmit="handleEmployeeFormSubmit"
      @onClearForm="clearForm"
    />
    <div class="service">
      <div class="service__header">
        <b-button
          variant="secondary"
          class="icon-button back-button"
          @click="goToServicesPage"
        >
          <i class="bx bx-arrow-back font-size-16" />
        </b-button>
        <h1 class="title-1">{{ service?.title ?? '' }}</h1>
      </div>
    </div>

    <b-card>
      <ServiceForm
        :initialData="service"
        :employeesOptions="employeesOptions"
        :employees="employees"
        @onSubmit="handleServiceFormSubmit"
        @onDeleteServiceSubmit="onDeleteServiceSubmit"
      />
    </b-card>
    <b-card v-if="service">
      <div class="d-md-flex align-items-center justify-content-between mb-4">
        <h3 class="mb-3 mb-md-0">Roles</h3>
        <div class="d-sm-flex">
          <b-button
            class="mr-3"
            variant="success"
            @click="handleRoleAdd"
          >
            Add Role
          </b-button>
        </div>
      </div>
       <b-table
        :items="service.roles"
        :fields="rolesTableFields"
        hover
        class="app-table"
      >
        <template #cell(index)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(actions)="{ item }">
          <div class="d-flex align-items-center justify-content-end">
            <i
              class="bx bx-pencil text-warning font-size-20 mr-3 cursor-pointer"
              @click="onRoleEditIconClick(item)"
            />
            <i
              class="bx bx-trash text-danger font-size-20 cursor-pointer"
              @click="onRoleDeleteIconClick(item.id)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
    <b-card v-if="service">
      <div class="d-md-flex align-items-center justify-content-between mb-4">
        <h3 class="mb-3 mb-md-0">Users</h3>
        <div class="d-sm-flex">
          <b-button
            class="mr-3"
            variant="success"
            @click="handleEmployeeAdd"
          >
            Add User
          </b-button>
        </div>
      </div>
      <b-table
        :items="users"
        :fields="usersTableFields"
        hover
        class="app-table"
      >
        <template #[`head(employee.name)`]>
          <div class="app-table__filter-input">
            <b-form-input
              v-model="filters.userName"
              type="text"
              placeholder="Name"
            />
            <i
              v-if="filters.userName"
              class="bx bxs-x-circle app-table__filter-clear"
              @mousedown.prevent="filters.userName = ''"
            />
          </div>
        </template>
        <template #[`head(email.email)`]>
          <div class="app-table__filter-input">
            <b-form-input
              v-model="filters.userEmail"
              type="text"
              class="app-table__filter-input"
              placeholder="Email"
            />
            <i
              v-if="filters.userEmail"
              class="bx bxs-x-circle app-table__filter-clear"
              @mousedown.prevent="filters.userEmail = ''"
            />
        </div>
        </template>
        <template #cell(index)="{ index }">
          {{ index + 1 }}
        </template>
         <template #cell(roles)="{ value }">
          {{ (value ?? []).map(({ title }) => title).join(', ') }}
        </template>
        <template #cell(actions)="{ item }">
          <div class="d-flex align-items-center justify-content-end">
            <i
              class="bx bx-pencil text-warning font-size-20 mr-3 cursor-pointer"
              @click="onEmployeeEditIconClick(item)"
            />
            <i
              class="bx bx-trash text-danger font-size-20 cursor-pointer"
              @click="onEmployeeDeleteIconClick(item.id)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
    <service-api-keys
      v-if="service"
      :list="service?.api_keys"
      :service-id="service?.id"
    />
  </Layout>
</template>
<script>
import { mapGetters } from 'vuex';
import Layout from '@/router/layouts/main.vue';
import RoleModalForm from './roleModalForm.vue';
import ServiceForm from './serviceForm.vue';
import UserServiceModalForm from './userModalForm.vue';
import { convertObjectToFormData } from '@/utils/converters';
import { FORM_MODE } from "@/utils/constants";
import ServiceApiKeys from '@/components/service-api-keys.vue';

export default {
  name: 'ServicePage',
  components: {
    Layout,
    RoleModalForm,
    ServiceForm,
    ServiceApiKeys,
    UserServiceModalForm
  },
  data() {
    return {
      modalRoleId: 'role-modal',
      modalUserId: 'user-modal',
      modalTitle: 'New',
      btnText: 'Save',
      currentRoleRow: null,
      currentEmployeeRow: null,
      currentOwnerRow: null,
      formMode: FORM_MODE.CREATE,
      usersTableFields: [
        { key: 'index', label: '', thStyle: { minWidth: '50px', width: '50px', maxWidth: '50px' } },
        { key: 'employee.name', label: 'Name', thStyle: { minWidth: '200px', width: '350px' } },
        { key: 'email.email', label: 'Email', thStyle: { minWidth: '200px', width: '350px' } },
        { key: 'login', label: 'Login' },
        { key: 'roles', label: 'Roles' },
        { key: 'actions', label: '', thStyle: { minWidth: '100px', width: '100px', maxWidth: '100px' } }
      ],
      rolesTableFields: [
        { key: 'index', label: '', thStyle: { minWidth: '50px', width: '50px', maxWidth: '50px' } },
        { key: 'title', label: 'Title' },
        { key: 'description', label: 'Description' },
        { key: 'actions', label: '', thStyle: { minWidth: '100px', width: '100px', maxWidth: '100px' } }
      ],
      filters: {
        userName: '',
        userEmail: ''
      }
    }
  },
  mounted() {
    this.$store.dispatch('employees/getEmployees');
    this.$store.dispatch('employees/getActiveEmployees');
    this.$store.dispatch('services/getService', {id: this.$route.params.id});
  },
  computed: {
    loading() {
      return this.$store.state.services.loading;
    },
    employees() {
      return this.$store.state.employees.list;
    },
    service() {
      return this.$store.state.services.service;
    },
    rolesOptions() {
      return this.$store.state.services.service.roles;
    },
    ...mapGetters('employees', ['employeesOptions']),
    users() {
      const [userName, userEmail] = [
        this.filters.userName.toLowerCase(),
        this.filters.userEmail.toLowerCase()
      ];
      let list = this.service?.service_employees ?? [];
      if (userName) {
        list = list.filter(({ employee }) => employee.name.toLowerCase().includes(userName));
      }
      if (userEmail) {
        list = list.filter(({ email }) => email.email.toLowerCase().includes(userEmail));
      }
      return list;
    }
  },
  methods: {
    goToServicesPage() {
      this.$router.push({ name: 'services' });
    },
    handleServiceFormSubmit(data) {
      this.$store.dispatch('services/updateService',
        {
          id: this.service.id,
          data: convertObjectToFormData({ ...{
              title: data.title,
              description: data.description,
              url: data.url,
              notification_enabled: data.notification_enabled,
            }, _method: 'PUT' })
        }
      ).then((res) => {
        if (res.code === 200) {
          this.$router.push('/services');
        }
      });
    },
    handleRoleFormSubmit(data) {
      this.$store.commit('services/SET_LOADING', true);
      if (this.formMode === FORM_MODE.CREATE) {
        this.$store.dispatch('roles/createRole', convertObjectToFormData({
          title: data.title,
          description: data.description,
          service_id: this.service.id,
        })).then(() => {
          this.$store.commit('services/SET_LOADING', false);
          this.$store.dispatch('services/getService', {id: this.service.id});
        });
      }
      if (this.formMode === FORM_MODE.EDIT) {
        this.$store.dispatch('roles/updateRole',
            {
              id: this.currentRoleRow.id,
              data: convertObjectToFormData({ ...{
                  title: data.title,
                  description: data.description,
                  service_id: this.$route.params.id,
                }, _method: 'PUT' })
            }
        ).then(() => {
          this.$store.commit('services/SET_LOADING', false);
          this.$store.dispatch('services/getService', {id: this.service.id});
        });
      }
      this.$bvModal.hide(this.modalRoleId);
    },
    handleEmployeeFormSubmit(data) {
      let isSuccessLoad = false
      if (this.formMode === FORM_MODE.CREATE) {
        this.$store.dispatch('services/addServiceUser', convertObjectToFormData({
          email_id: data.email,
          employee_uid: data.owner.value,
          service_id: this.service.id,
          roles: data.roles.map(({ id }) => id),
          login: data.login,
        })).then((res) => {
          if (res && res.code === 200) {
            isSuccessLoad = true
            this.$store.dispatch('services/getService', {id: this.service.id});
          }
        });
      }
      if (this.formMode === FORM_MODE.EDIT) {
        this.$store.dispatch('services/updateServiceUser',
          {
            id: this.currentEmployeeRow.id,
            data: {
              email_id: data.email,
              employee_uid: data.owner?.value,
              service_id: this.service.id,
              roles: data.roles.map(({ id }) => id),
              login: data.login,
            }
          }
        ).then((res) => {
          if (res.code === 200) {
            isSuccessLoad = true
            this.$store.dispatch('services/getService', {id: this.service.id});
          }
        });
      }
      if (isSuccessLoad) {
        this.$bvModal.hide(this.modalUserId);
      }
    },
    onEmployeeDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this employee?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$store.dispatch('services/deleteServiceUser', id)
              .then(() => {
                this.$store.dispatch('services/getService', {id: this.service.id});
              });
            }
          })
    },
    onRoleDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this role?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value) => {
        if (value) {
          this.$store.dispatch('roles/deleteRole', {
            id,
            data: convertObjectToFormData({ _method: 'DELETE'})
          }).then(() => {
            this.$store.dispatch('services/getService', {id: this.service.id});
          });
        }
      })
    },
    onDeleteServiceSubmit() {
      this.$bvModal.msgBoxConfirm('Delete this service?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value) => {
        if (value) {
          this.$store.dispatch('services/deleteService', {
            id: this.service.id,
            data: convertObjectToFormData({ _method: 'DELETE'})
          }).then(()  => {
            this.$router.push(`/services`);
          });
        }
      });
    },
    clearForm() {
      this.currentRoleRow = null;
      this.currentEmployeeRow = null;
    },
    handleRoleAdd() {
      this.currentRoleRow = null;
      this.modalTitle = 'New Role';
      this.btnText = 'Save';
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalRoleId);
    },
    onRoleEditIconClick(item) {
      this.modalTitle = 'Edit Role';
      this.btnText = 'Update';
      this.currentRoleRow = item;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalRoleId);
    },
    handleEmployeeAdd() {
      this.modalTitle = 'New User';
      this.btnText = 'Save';
      this.currentEmployeeRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalUserId);
    },
    onEmployeeEditIconClick(item) {
      this.modalTitle = 'Edit User';
      this.btnText = 'Update';
      this.currentEmployeeRow = item;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalUserId);
    },
    openService(id) {
      this.$router.push(`/services/${id}`);
    },
  }
}
</script>

<style lang="scss" scoped>
.service {
  &__header {
    display: flex;
    margin: 20.5px 0px;
    align-items: center;
  }
}
.title-1 {
  text-transform: capitalize;
}
</style>
