<template>
  <b-card>
    <div class="d-md-flex align-items-center justify-content-between mb-4">
      <h3 class="mb-3 mb-md-0">API Keys</h3>
      <div class="d-sm-flex">
        <b-button
          class="mr-3"
          variant="success"
          @click="onAddButtonClick"
        >
          Add API Key
        </b-button>
      </div>
    </div>
    <b-table
      :items="list"
      :fields="fields"
      hover
      responsive
      class="app-table"
    >
      <template #cell(index)="{ index }">
        {{ index + 1 }}
      </template>
      <template #cell(employees)="{ value: employees, index }">
        <div
          v-for="employee in employees"
          :key="`${index}-${employee.uid}`"
        >
          {{ employee.name }}
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-flex align-items-center justify-content-end">
          <i
            class="bx bx-pencil text-warning font-size-20 mr-3 cursor-pointer"
            @click="onEditIconClick(item)"
          />
          <i
            class="bx bx-trash text-danger font-size-20 cursor-pointer"
            @click="onDeleteIconClick(item.id)"
          />
        </div>
      </template>
    </b-table>
    <api-keys-form-modal
      v-model="isModalVisible"
      :initial-data="editableKey"
      :service-id="serviceId"
      @hide="onModalHide"
      @submit-success="onSubmitSuccess"
    />
  </b-card>
</template>

<script>
import apiKeysMixin from '@/mixins/api-keys';
import ApiKeysFormModal from '@/components/api-keys/api-keys-form-modal.vue';

export default {
  components: { ApiKeysFormModal },
  mixins: [apiKeysMixin],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    serviceId: Number
  },
  created() {
    this.fields[0] = { 
      key: 'index', label: '', thStyle: { minWidth: '50px', width: '50px', maxWidth: '50px' }
    };
  },
  methods: {
    async fetchList() {
      await this.$store.dispatch('services/getService', { id: this.serviceId });
    }
  }
}
</script>